import * as IO from "../../helpers/io.js";
//
// Runtime model.
//
export class BlobLinkClass extends IO.Type {
    constructor(type) {
        const model = IO.object({
            hash: IO.string,
            type: type,
            size: IO.number,
            name: IO.string,
        });
        super("BlobLink", model.is, model.validate, model.encode);
    }
}
function blobLink(type) {
    return new BlobLinkClass(IO.literal(type));
}
const RAnyBlobLink = new BlobLinkClass(IO.string);
//
// I/O.
//
function buildBlobLink(blobResponse, type, name) {
    return {
        hash: blobResponse.hash,
        size: blobResponse.size,
        type,
        name,
    };
}
//
// Exports.
//
export const BlobLink = {
    io: blobLink,
    new: buildBlobLink,
};
export const AnyBlobLink = {
    io: () => RAnyBlobLink,
};
